<script lang="ts" setup>
import type { PageHeaderRecord } from "~~/server/graphql/datocms/generated";

const props = defineProps<{ data: PageHeaderRecord }>();

const localePath = useLocalePath();

const ctaList = computed(() => {
  return props.data.ctaList.map((c) => {
    const link = getLinkForType(c.linkItem, localePath);

    return {
      id: c.id,
      label: link.label,
      url: link.href ?? "/",
      primary: c.primary,
    };
  });
});
</script>

<template>
  <div class="relative" :class="$attrs.class">
    <div
      v-if="data.optImage"
      class="overlay bg-img relative h-[718px]"
      :class="{ 'overlay--primary': data.colorMode, 'overlay--neutral': !data.colorMode }"
      :style="{ 'background-image': `url('${data.optImage?.responsiveImage.src}')` }"
    />

    <div
      class="z-20 size-full"
      :class="{
        'absolute': data.optImage,
        'top-0': data.optImage,
        'bg-primary': !data.optImage && data.colorMode,
        'bg-neutral': !data.optImage && !data.colorMode }"
    >
      <UContainer
        class="py-xl sm:py-4xl inset-x-0"
        :class="{
          'absolute': data.optImage,
          'bottom-0': data.optImage }"
      >
        <div class="gap-xl flex flex-col">
          <h1 class="uppercase text-white" v-html="data.title" />

          <div v-if="data.optIntro" class="text-balance text-white" v-html="data.optIntro" />

          <div v-if="ctaList.length" class="gap-sm flex flex-col sm:flex-row">
            <div v-for="cta of ctaList" :key="cta.id">
              <UButton v-if="cta.primary" :to="cta.url" color="white" icon="i-ph-arrow-right" trailing>
                {{ cta.label }}
              </UButton>

              <UButton v-if="!cta.primary" :to="cta.url" color="white" variant="outline" icon="i-ph-arrow-right" trailing>
                {{ cta.label }}
              </UButton>
            </div>
          </div>
        </div>
      </UContainer>
    </div>
  </div>
</template>

<style scoped>
.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay::before {
  content: "";
  z-index: 10;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}

.overlay--primary::before {
  background: linear-gradient(0deg, rgb(var(--color-primary) / 0.96) 0%, rgb(var(--color-primary) / 0) 100%);
}

.overlay--neutral::before {
  background: linear-gradient(0deg, rgb(var(--color-black) / 1) 0%, rgb(var(--color-black) / 0) 100%);
}
</style>
